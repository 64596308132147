<template>
<div class="file-preview" v-if="state.showFilePreview"
  @click.stop="handleClose">
  <div class="preview-main">
    <el-carousel trigger="click"
      @click.stop
      height="92vh"
      ref="filePreviewRef"
      :autoplay="false"
      :loop="false"
      indicator-position="none"
      arrow="never"
      @change="swiperChange">
      <el-carousel-item
        v-for="(item, index) of state.files"
        :key="index">
        <el-scrollbar height="92vh">
          <el-image style="display: block; width: 100%;" :src="item" />
        </el-scrollbar>
      </el-carousel-item>
    </el-carousel>

    <div class="swiper-indicator" @click.stop>
      <el-button
        :icon="'ArrowLeftBold'" circle size="large"
        @click="handlePrevPage">
      </el-button>
      <ul class="indicator-page-num">
        <li>{{ state.swiperIndex + 1 }}</li>/
        <li>{{ state.files.length }}</li>
      </ul>
      <el-button
        :icon="'ArrowRightBold'" circle size="large"
        @click="handleNextPage">
      </el-button>
    </div>

    <div class="icon-close" @click.stop="handleClose">
      <el-icon :size="40"><Close /></el-icon>
    </div>
  </div>
</div>
</template>
<script setup>
import { reactive, defineExpose, ref } from 'vue'
import { ElMessage } from 'element-plus'
import { fileView } from '@/api'
const filePreviewRef = ref(null)

const state = reactive({
  showFilePreview: false,
  swiperIndex: 0,
  files: []
})

function show (item) {
  console.log(item)
  fileView({ id: item.id }).then(res => {
    console.log('预览', res)
    if (res?.files.length) {
      state.files = res.files
      state.showFilePreview = true
    } else {
      ElMessage({
        type: 'warning',
        message: '未查到可预览数据'
      })
    }
  })
}

function handleClose () {
  state.showFilePreview = false
}

function swiperChange (index) {
  console.log(index)
}

function handlePrevPage () {
  if (state.swiperIndex > 0) {
    state.swiperIndex = state.swiperIndex - 1
    filePreviewRef.value.setActiveItem(state.swiperIndex)
  }
}

function handleNextPage () {
  if (state.swiperIndex < state?.files.length - 1) {
    state.swiperIndex = state.swiperIndex + 1
    filePreviewRef.value.setActiveItem(state.swiperIndex)
  }
}

defineExpose({
  show
})
</script>

<style lang="scss" scoped>
.file-preview {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba($color: #000000, $alpha: .4);
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
  .preview-main {
    width: 800px;
    background: #FFFFFF;
    border-radius: 4px;
    position: relative;
    overflow: hidden;
    .swiper-indicator {
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      .indicator-page-num {
        display: flex;
        align-items: center;
        margin: 20px;
        font-size: 24px;
      }
    }
    .icon-close {
      position: absolute;
      top: 10px;
      right: 10px;
      z-index: 100;
      cursor: pointer;
    }
  }
}
</style>
