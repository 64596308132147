<template>
  <div style="margin-bottom: 15px;">
    <el-radio-group v-model="state.ruleForm.type" @change="changeType">
      <el-radio-button label="2">常规查询</el-radio-button>
      <el-radio-button label="1">年份统计</el-radio-button>
    </el-radio-group>
  </div>

  <el-form
    v-if="state.ruleForm.type === '2'"
    ref="ruleFormRef"
    :model="state.ruleForm"
    :inline="true"
    @keyup.enter="submitForm"
    status-icon>
    <el-form-item>
      <el-date-picker
        v-model="state.ruleForm.date"
        type="daterange"
        value-format="YYYY-MM-DD"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
      />
    </el-form-item>

    <el-form-item>
      <el-input v-model="state.ruleForm.title" placeholder="请输入关键字" />
    </el-form-item>

    <el-form-item>
      <el-button type="primary" @click="submitForm()">
        搜索
      </el-button>
    </el-form-item>
  </el-form>

  <el-date-picker
    v-else
    style="margin-bottom: 18px;"
    v-model="state.ruleForm.year"
    type="year"
    placeholder="选择年份"
    value-format="YYYY"
    @change="chooseYear"
    :disabled-date="disabledDate"
  />

  <div style="font-size: 14px; margin-bottom: 15px;">
    共 <el-tag class="ml-2" type="success" style="font-size: 18px;">{{ state.total }}</el-tag> 条文件
  </div>

  <el-table :data="state.tableData" stripe border style="width: 100%" v-loading="state.loading">
    <el-table-column label="序号" align="center" type="index" width="60" />
    <el-table-column label="文件名" prop="title" />

    <el-table-column label="发起时间">
      <template #default="scope">
        <div style="display: flex; align-items: center">
          <el-icon><timer /></el-icon>
          <span style="margin-left: 10px">{{ scope.row.create_time }}</span>
        </div>
      </template>
    </el-table-column>

    <el-table-column label="归档时间">
      <template #default="scope">
        <div style="display: flex; align-items: center">
          <el-icon><timer /></el-icon>
          <span style="margin-left: 10px">{{ scope.row.update_time }}</span>
        </div>
      </template>
    </el-table-column>

    <el-table-column label="备注" width="88">
      <template #default="scope">
        <el-tooltip
          v-if="scope.row.archive_remark"
          effect="dark"
          :content="scope.row.archive_remark"
          placement="top-start">
          <el-button type="success" link>查看详情</el-button>
      </el-tooltip>
      </template>
    </el-table-column>

    <el-table-column label="操作" :width="roles === '4' ? '73' : '280'">
      <template #default="scope">
        <el-button
          size="small"
          type="success"
          @click="handleFilePreview(scope.row)">
          预览
        </el-button>
        <el-button
          v-if="roles !== '4'"
          size="small"
          type="primary"
          @click="handleSeal(scope.row)">
          盖章
        </el-button>
        <el-button
          v-if="roles !== '4'"
          size="small"
          type="info"
          @click="handleDownload(scope.row)">
          下载
        </el-button>
        <el-button
          v-if="roles !== '4'"
          size="small"
          @click="handleViewProgress(scope.row)">
          进度查看
        </el-button>
      </template>
    </el-table-column>
  </el-table>
  <el-pagination
    v-if="state.total"
    style="margin-top: 15px;"
    small
    background
    layout="prev, pager, next"
    :total="state.total"
    :page-size="state.pageOptions.page_size"
    @current-change="currentPageChange"
  />

  <FilePreview ref="filePreviewRef"></FilePreview>
  <ViewProgress  ref="viewProgressRef"></ViewProgress>
  <SealDialog ref="sealDialogRef"></SealDialog>
</template>

<script setup>
import { reactive, ref } from 'vue'
import { ElMessage, ElMessageBox } from 'element-plus'
import { queryFileFinishList, queryFileSecretaryList } from '@/api'
import { baseApi } from '@/api/host'
import FilePreview from '@/components/FilePreview.vue'
import ViewProgress from '@/components/ViewProgress.vue'
import SealDialog from '@/components/SealDialog.vue'
import { getCookie } from '@/utils'
const filePreviewRef = ref(null)
const viewProgressRef = ref(null)
const sealDialogRef = ref(null)
const roles = getCookie('roles')

function changeType (type) {
  console.log(type)
  state.pageOptions.page = 1
}

// 搜索表单
const state = reactive({
  loading: false,
  ruleForm: {
    type: '2',
    title: '',
    date: [],
    year: ''
  },
  pageOptions: {
    page: 1,
    page_size: 10
  },
  total: 0,
  tableData: []
})

async function submitForm () {
  console.log('submit!', state.ruleForm)
  const params = {
    type: state.ruleForm.type,
    title: state.ruleForm.title,
    ...state.pageOptions,
    start_date: state.ruleForm.date[0],
    end_date: state.ruleForm.date[1]
  }
  console.log('常规查询参数', params)
  queryFileFinishListApi(params)
}

// 选择年份
function chooseYear (year) {
  console.log('year', parseInt(year))
  if (parseInt(year) >= 2022) {
    const params = {
      type: state.ruleForm.type,
      ...state.pageOptions,
      year: state.ruleForm.year
    }
    console.log('年份查询参数', params)
    queryFileFinishListApi(params)
  } else {
    state.ruleForm.year = '2022'
  }
}

function disabledDate (time) {
  return time.getTime() < new Date('2022')
}

// 预览
function handleFilePreview (item) {
  window.open(`${item.source_pdf}?t=${new Date().getTime()}`)
  // filePreviewRef.value.show(item)
}

// 盖章
function handleSeal (item) {
  console.log(item)
  sealDialogRef.value.show(item)
}

// 下载
function handleDownload (item) {
  ElMessageBox.confirm(
    `确认下载【${item.title}】吗?`,
    '提示',
    {
      confirmButtonText: '确认',
      cancelButtonText: '取消',
      type: 'warning'
    }
  ).then(() => {
    window.open(`${baseApi}/api/cms/file/download?id=${item.id}`)
  }).catch(() => {
    ElMessage({
      type: 'info',
      message: '取消下载'
    })
  })
}

// 进度查看
function handleViewProgress (item) {
  console.log('进度查看', item)
  viewProgressRef.value.show(item)
}

// 页码改变
function currentPageChange (page) {
  console.log('页码改变', page)
  state.pageOptions.page = page || 1
  if (state.ruleForm.type === '2') {
    submitForm()
  } else {
    chooseYear()
  }
}

queryFileFinishListApi()
function queryFileFinishListApi (params) {
  state.loading = true
  if (roles === '4') {
    // 秘书文件已归档列表
    queryFileSecretaryList(params || {}).then(res => {
      state.tableData = res.data
      state.total = res.total
      state.loading = false
    })
  } else {
    queryFileFinishList(params || {}).then(res => {
      state.tableData = res.data
      state.total = res.total
      state.loading = false
    })
  }
}
</script>

<style lang="scss" scoped>

</style>
