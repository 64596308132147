<template>
  <el-dialog v-model="state.showSeal" :width="fileW + 42 + 'px'">
    <div class="seal" :style="state.styleImg">
      <div id="seal-box" :style="state.sealBox"></div>
    </div>
    <template #footer>
      <el-button
        type="primary"
        icon="Stamp"
        :loading="state.loading" :disabled="state.loading"
        @click="fileAffixsealApi()">
        确认盖章
      </el-button>
    </template>
  </el-dialog>
</template>
<script setup>
import { reactive, defineExpose, nextTick } from 'vue'
import { ElMessage } from 'element-plus'
import { fileView, fileAffixseal } from '@/api'

let documentId = '' // 文件ID
const scale = 1 // 缩放比例
const fileW = 595 * scale // 盖章文件宽度
const fileH = 842 * scale // 盖章文件高度
const realW = 66 * scale // 印章宽度
const realH = 32 * scale // 印章高度
let x = 0 // 印章坐标 x
let y = 0 // 印章坐标 y

const state = reactive({
  showSeal: false,
  loading: false,
  styleImg: {
    width: `${fileW}px`,
    height: `${fileH}px`
  },
  sealBox: {
    width: `${realW}px`,
    height: `${realH}px`
  }
})

function show (item) {
  documentId = item.id
  fileView({ id: item.id }).then(res => {
    state.showSeal = true
    if (res?.files.length) {
      state.styleImg.background = `url('${res.files[0]}?t=${Date.now()}') no-repeat center center`
      state.styleImg.backgroundSize = 'cover'
      nextTick(() => {
        drag()
      })
    }
  })
}

function drag () {
  const box = document.getElementById('seal-box') // 获取 box 对象
  box.style.left = '0px'
  box.style.top = '0px'
  // 鼠标在 box 按下事件
  box.onmousedown = function (event) {
    const e = event || window.event // 事件对象兼容判断
    const cx = e.clientX // 当前鼠标相对于浏览器水平距离的位置
    const cy = e.clientY // 当前鼠标相对于浏览器垂直距离的位置

    const bx = box.offsetTop // 获取 box 相对于 body 的 top 位置
    const by = box.offsetLeft // 获取 box 相对于 body 的 left 位置

    // 获取鼠标相对于 box 的坐标位置
    const rx = cx - by // 鼠标点击位置相对于 box 边框的水平距离
    const ry = cy - bx // 鼠标点击位置相对于 box 边框的垂直距离

    // 鼠标在 box 移动事件
    document.onmousemove = function (event) {
      const e = event || window.event // 事件对象兼容判断

      const left = e.clientX - rx
      const top = e.clientY - ry
      x = left > 0 ? left : 0
      y = top > 0 ? top : 0
      x = x <= fileW - realW ? x : fileW - realW
      y = y <= fileH - realH ? y : fileH - realH

      box.style.left = `${x}px`
      box.style.top = `${y}px`
    }
  }

  // 绑定鼠标抬起事件
  box.onmouseup = function () {
    // 取消鼠标移动事件
    console.log(x, y)
    document.onmousemove = function () {}
  }
}

// 盖章接口
function fileAffixsealApi () {
  state.loading = true
  const params = {
    document_id: documentId,
    x: x / scale,
    y: y / scale
  }
  fileAffixseal(params).then(res => {
    ElMessage({
      type: 'success',
      message: '盖章成功'
    })
    state.loading = false
    state.showSeal = false
  }).catch(err => {
    ElMessage({
      type: 'error',
      message: err.toString()
    })
  })
}

defineExpose({
  show
})
</script>

<style lang="scss" scoped>
.seal {
  position: relative;
  border: 1px solid #d8afcc;
  #seal-box {
    background: url('~@/assets/images/seal.png') no-repeat center center;
    background-size: cover;
    position: absolute;
  }
}
</style>
