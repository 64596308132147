<template>
  <el-dialog v-model="state.showProgressDialog" width="40%">
    <ul style="margin-bottom: 10px; font-size: 16px;">
      <li>
        <span style="font-weight: bold;">文件名: </span>
        <span style="color: red;">{{ state.title }}</span>
      </li>
      <li>
        <span style="font-weight: bold;">来 &nbsp; 源: </span>
        <span style="color: #409eff;">{{ state.origin }}</span>
      </li>
    </ul>
    <el-scrollbar height="500px">
      <div style="padding: 10px;">
        <el-timeline>
          <el-timeline-item
            v-for="(activity, index) in state.progressList"
            :key="index"
            :type="index === 0 ? 'primary': ''"
            :size="index === 0 ? 'large' : 'normal'"
            :hollow="index === 0"
            :timestamp="activity.update_time">
            <el-tag :type="index === 0 ? 'success' : 'info'">{{ activity.operator }}</el-tag>
            {{activity.content}}
          </el-timeline-item>
        </el-timeline>
      </div>
    </el-scrollbar>
  </el-dialog>
</template>
<script setup>
import { reactive, defineExpose } from 'vue'
import { fileProcess } from '@/api'

const state = reactive({
  showProgressDialog: false,
  title: '',
  origin: '',
  progressList: [
    {
      id: 19,
      document_id: 26,
      operator: 'admin',
      content: '进行文件上传',
      create_time: '2022-07-24 13:01:09',
      update_time: '2022-07-24 13:01:09'
    },
    {
      id: 19,
      document_id: 26,
      operator: 'admin',
      content: '进行文件上传',
      create_time: '2022-07-24 13:01:09',
      update_time: '2022-07-24 13:01:09'
    },
    {
      id: 19,
      document_id: 26,
      operator: 'admin',
      content: '进行文件上传',
      create_time: '2022-07-24 13:01:09',
      update_time: '2022-07-24 13:01:09'
    }
  ]
})

function show (item) {
  fileProcess({ id: item.id }).then(res => {
    state.title = res.title
    state.origin = res.origin
    state.progressList = res.logs.reverse()
    state.showProgressDialog = true
  })
}

defineExpose({
  show
})
</script>

<style scoped>
.footer_text {
  margin-right: 20px;
}
</style>
